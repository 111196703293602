
import {
    defineComponent, PropType, ref, reactive
} from 'vue';
import { residentDeviceApi } from '@/api';
import List from '@/components/common/list/src/list.vue';
import CameraDevicesOperateDialog
    from '@/components/view/installer/community/build-content-model/components/camera-devices-operate-dialog.vue';
import addButton from '@/components/common/add-button/index.vue';
import { ListActionItem } from '@/components/common/list';
import removeFunc from '@/methods/remove-func';
import { EventBusType } from '@/util/use-event-bus/types/eventbus';
import { updateSortField, getSortImg, getInitSortField } from '@/methods/sort-table';

export default defineComponent({
    components: {
        List,
        CameraDevicesOperateDialog,
        addButton
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        uuid: {
            type: String,
            default: ''
        },
        eventBus: {
            type: Object as PropType<EventBusType>
        }
    },
    setup(props, { emit }) {
        // 获取build和room框数据
        const updateData = ref(0);
        const column = [
            {
                name: 'Location',
                label: WordList.TabelPersonDeviceInHtmlLocation,
                headerType: 'customize'
            }, {
                name: 'LinkDevice',
                label: WordList.LinkDevice
            }, {
                name: 'CreateTime',
                label: WordList.TabelMessageBoxCreateTime,
                headerType: 'customize'
            }
        ];

        // 表格数据获取
        const tableData = ref<object[]>([]);

        // 获取初始排序
        const initIntercomSortRes = getInitSortField('aptInfoThirdPartyDevice');
        const thirdPartyDeviceParam = reactive({
            ID: props.id,
            SortField: initIntercomSortRes.sortField,
            Sort: initIntercomSortRes.sort
        });
        // 获取第三方设备数据
        const getThirdPartyDeviceApi = () => {
            residentDeviceApi.thirdPartCameraGetListForOwner(
                { ...thirdPartyDeviceParam },
                (res: object[]) => {
                    tableData.value = res;
                    updateData.value += 1;
                    emit('settingThirdDeviceCount', tableData.value.length);
                }
            );
        };
        // 获取排序方式, 更新表格数据
        function getSortRes(table: string, sortField: string) {
            const {
                sort
            } = updateSortField(table, sortField);
            thirdPartyDeviceParam.Sort = sort;
            thirdPartyDeviceParam.SortField = sortField;
            getThirdPartyDeviceApi();
        }

        getThirdPartyDeviceApi();
        props.eventBus!.on('thirdPartyRefresh', getThirdPartyDeviceApi);

        // 操作
        const isShowOperateDialog = ref(false);
        const operateType = ref<'add'|'edit'>('add');
        const editData = ref({});

        const add = () => {
            operateType.value = 'add';
            isShowOperateDialog.value = true;
        };

        const edit = (item: object) => {
            operateType.value = 'edit';
            editData.value = { ...item, LevelID: props.uuid, Grade: 3 };
            isShowOperateDialog.value = true;
        };

        // 删除
        const deleteThirdPartyDeviceApi = (data: { UUID: string }) => {
            removeFunc(WordList.TabelDeleteText, () => {
                residentDeviceApi.thirdPartCameraDelete({ UUID: data.UUID }, getThirdPartyDeviceApi);
            });
        };

        const cameraClose = () => {
            isShowOperateDialog.value = false;
        };

        // action操作 迁移备注：这部分不用迁移 主要是为了适配删除触发点击
        const action: ListActionItem[] = [
            {
                type: 'edit2',
                event(data) {
                    edit(data);
                },
                class: 'el-icon-my-modify modify-icon',
                title: WordList.TabelConfigInHtmlModify
            },
            {
                type: 'delete2',
                event(data: { UUID: string }) {
                    deleteThirdPartyDeviceApi(data);
                },
                class: 'el-icon-delete delete-icon',
                title: WordList.TabelConfigInHtmlDelete
            }
        ];

        return {
            updateData,
            column,
            tableData,
            getThirdPartyDeviceApi,
            action,
            cameraClose,
            add,
            isShowOperateDialog,
            operateType,
            editData,
            getSortImg,
            getSortRes
        };
    }
});
