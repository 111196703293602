
import {
    computed,
    defineComponent, PropType, reactive, ref
} from 'vue';
import { CameraOperateAdd, CameraOperateEdit, LinkDeviceList } from '@/components/view/installer/community/build-content-model/type/camera-devices';
import DialogShell from '@/components/common/dialog-shell/index.vue';
import List from '@/components/common/list/src/list.vue';
import DeviceIcon from '@/components/view/common/device-icon/index.vue';
import residentDevice from '@/api/community/resident-device';
import { checkRtspAddress, checkRtspUserName, checkRtspPassword } from '@/methods/rules/device';

export default defineComponent({
    components: {
        DialogShell,
        List,
        DeviceIcon
    },
    props: {
        operate: {
            type: String as PropType<'add'|'edit'>,
            default: 'add'
        },
        cameraInfo: {
            type: Object as PropType<CameraOperateEdit>,
            default: {}
        },
        // 1指社区公共设备2楼栋设备3个人设备
        grade: {
            type: Number as PropType<1|2|3>,
            default: 1
        },
        buildId: {
            type: String,
            default: ''
        },
        personalAccountId: {
            type: String,
            default: ''
        }
    },
    emits: ['close', 'success'],
    setup(props, { emit }) {
        const formRef = ref();
        const formData = props.operate === 'add'
            ? reactive<CameraOperateAdd>({
                Location: '',
                RtspAddress: 'rtsp://',
                RtspUserName: '',
                RtspPwd: '',
                IsLinkDevice: 0,
                MAC: '',
                Grade: props.grade
            })
            : reactive<CameraOperateEdit>({
                ...props.cameraInfo
            });

        // 编辑保存初始勾选的MAC
        const initMAC = props.operate === 'edit' && formData.MAC;

        const rules = {
            Location: [
                { required: true, message: WordList.PleaseEnterTheFormat.format(WordList.TabelPersonDeviceInHtmlLocation), trigger: 'change' },
                { max: 63, message: WordList.LocationTooLong, trigger: 'change' }

            ],
            RtspAddress: [
                {
                    validator: checkRtspAddress,
                    trigger: 'change'
                },
                { max: 512, message: WordList.InvalidRTSPAddress, trigger: 'change' }
            ],
            RtspUserName: [
                { required: true, message: WordList.PleaseEnterTheFormat.format(WordList.UserName.toLocaleLowerCase()), trigger: 'change' },
                { max: 63, message: WordList.UserNameTooLong, trigger: 'change' },
                {
                    validator: checkRtspUserName,
                    trigger: 'change'
                }
            ],
            RtspPwd: [
                { required: true, message: WordList.PleaseEnterTheFormat.format(WordList.TabelPersonUserInHtmlPassword), trigger: 'change' },
                { max: 63, message: WordList.RtspPwdTooLong, trigger: 'change' },
                {
                    validator: checkRtspPassword,
                    trigger: 'change'
                }
            ]
        };

        // link Device
        const column = [
            {
                name: 'Radio',
                label: '',
                type: 'customize'
            }, {
                name: 'Location',
                label: WordList.TabelPersonDeviceInHtmlLocation
            }, {
                name: 'MAC',
                label: WordList.TabelPersonDeviceInHtmlMAC
            }, {
                name: 'DeviceType',
                label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
                type: 'customize'
            }
        ];

        const updateData = ref(0);
        const tableData = ref<LinkDeviceList[]>([]);
        const IsLinkedMAC = ref<string[]>([]);

        const gradeId = computed(() => {
            if (props.operate === 'add') {
                // eslint-disable-next-line no-nested-ternary
                return props.grade === 1 ? '' : props.grade === 2 ? props.buildId : props.personalAccountId;
            }
            return props.cameraInfo.LevelID;
        });
        residentDevice.thirdPartCameraGetLinkDeviceList({
            Grade: props.operate === 'add' ? props.grade : props.cameraInfo.Grade,
            ID: gradeId.value
        }, (res: {
            LinkDevice: LinkDeviceList[];
            IsLinkedMAC: string[];
        }) => {
            tableData.value = res.LinkDevice;
            IsLinkedMAC.value = res.IsLinkedMAC;
            updateData.value += 1;
        });

        function close() {
            emit('close');
        }

        function submit() {
            formRef.value.validate((valid: boolean) => {
                if (valid) {
                    const fn = props.operate === 'add'
                        ? residentDevice.thirdPartCameraAdd
                        : residentDevice.thirdPartCameraEdit;

                    let data: CameraOperateAdd = { ...formData };
                    if (props.operate === 'add') {
                        data = {
                            ...formData,
                            UnitID: props.grade === 2 ? gradeId.value : '',
                            PersonalAccountUUID: props.grade === 3 ? gradeId.value : ''
                        };
                    }
                    fn(data, () => {
                        emit('success');
                        close();
                    });
                }
            });
        }

        return {
            formRef,
            formData,
            submit,
            rules,
            tableData,
            column,
            updateData,
            close,
            IsLinkedMAC,
            initMAC
        };
    }
});
